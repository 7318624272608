import { memo, useCallback, useRef } from 'react'

import { Text } from '@cash-me/react-components'
import NextImage from "next/image"
import type { Swiper } from 'swiper/types'

import { Carousel } from 'components/carousel'
import { Grid, Col } from 'components/grid'
import { pushDataLayer } from 'utils/push-data-layer'

import * as S from './styles'

type SwiperReference = HTMLElement & {
  swiper: Swiper
}

const sliderValues = ['Facilidade e transparência', 'Flexibilidade', 'Soluções tecnológicas', 'Atendimento humanizado']

const Benefits = memo(() => {
  const swiperElRef = useRef<SwiperReference | null>(null)

  const handleChangeIndex = useCallback((index: number) => {
    const imageCarouselInstance = swiperElRef.current?.swiper
    if (imageCarouselInstance) imageCarouselInstance.slideTo(index)

    pushDataLayer({
      category: 'Slider',
      action: 'Vantagens',
      label: `Slider - ${sliderValues[index]}`
    })
  }, [])

  return (
    <S.Benefits>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col start={{ desktop: 2 }} col={{ tablet: 5, desktop: 5 }}>
          <S.ImagesWrapper>
            <swiper-container ref={swiperElRef} slides-per-view="1">
              <swiper-slide>
                <S.Image>
                  <NextImage
                    src="/images/home/easiness.png"
                    alt="Facilidade"
                    width="600"
                    height="567"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.Image>
              </swiper-slide>
              <swiper-slide>
                <S.Image>
                  <NextImage
                    src="/images/home/flexibility.png"
                    alt="Flexibilidade"
                    width="600"
                    height="567"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.Image>
              </swiper-slide>
              <swiper-slide>
                <S.Image>
                  <NextImage
                    src="/images/home/service.png"
                    alt="Soluções tecnológicas"
                    width="602"
                    height="576"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.Image>
              </swiper-slide>
              <swiper-slide>
                <S.Image>
                  <NextImage
                    src="/images/home/solutions.png"
                    alt="Atendimento humanizado"
                    width="601"
                    height="576"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.Image>
              </swiper-slide>
            </swiper-container>
          </S.ImagesWrapper>
        </Col>

        <Col start={{ tablet: 7, desktop: 8 }} col={{ tablet: 5, desktop: 4 }}>
          <S.TextWrapper>
            <Text as="h2" size="caption" weight="medium" uppercase color="var(--colors-neutral-text-default)">
              Vantagens
            </Text>
            <Carousel navigation={true} onChangeIndex={handleChangeIndex}>
              <S.CarouselItem>
                <Text as="h3" size="heading2" weight="medium">
                  Facilidade e <br />
                  transparência
                </Text>
                <Text>Consiga crédito com agilidade e clareza para realizar os seus projetos com confiança.</Text>
              </S.CarouselItem>
              <S.CarouselItem>
                <Text as="h3" size="heading2" weight="medium">
                  Flexibilidade
                </Text>
                <Text>Aqui, você encontra a opção de empréstimo que mais combina com você.</Text>
              </S.CarouselItem>
              <S.CarouselItem>
                <Text as="h3" size="heading2" weight="medium">
                  Soluções <br />
                  tecnológicas
                </Text>
                <Text>Acompanhe a sua solicitação de crédito pelo smartphone ou computador.</Text>
              </S.CarouselItem>
              <S.CarouselItem>
                <Text as="h3" size="heading2" weight="medium">
                  Atendimento <br />
                  humanizado
                </Text>
                <Text>Tenha uma equipe sempre pronta para te auxiliar com o que você precisa.</Text>
              </S.CarouselItem>
            </Carousel>
          </S.TextWrapper>
        </Col>
      </Grid>
    </S.Benefits>
  );
})

Benefits.displayName = 'Benefits'

export default Benefits
