/* eslint-disable max-len */
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, space, media, fontSizes } = theme

export const Benefits = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colors['neutral-background-default']};
  overflow: hidden;
  padding-top: ${space['spacing-xxl']};
  padding-bottom: ${space['spacing-lg']};
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h2 {
    margin-bottom: ${space['spacing-md']};

    @media (${media.desktop}) {
      margin-bottom: ${space['spacing-xl']};
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: ${space['spacing-md']};
  overflow: hidden;
  cursor: grab;

  h3 {
    display: flex;
    align-items: flex-end;
    height: 5.375rem;
    margin-bottom: ${space['spacing-sm']};

    @media (${media.desktop}) {
      height: 6.562rem;
      margin-bottom: ${space['spacing-xl']};
    }
  }

  p {
    padding-right: ${space['spacing-sm']};
  }
`

export const ImagesWrapper = styled.div`
  overflow: hidden;
  margin-bottom: ${space['spacing-sm']};
  cursor: grab;
`

export const Image = styled.div`
  display: inline-block;
  width: 100%;
  padding: ${space['spacing-sm']};
`
